<template>
  <div class="page-cu-container">
    <div class="page-cu-top">
      <a-form layout="inline">
        <a-form-item>
          <a-input v-model="page.key" placeholder="请输入关键字"></a-input>
        </a-form-item>
        <a-form-item>
          <a-select
            style="width: 120px"
            v-model="page.state"
            placeholder="请选择状态"
          >
            <a-select-option value="1">有效</a-select-option>
            <a-select-option value="2">无效</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item>
          <a-button type="primary" @click="searchList" icon="search"
            >搜索</a-button
          >
        </a-form-item>
        <a-form-item>
          <a-button type="default" @click="addAction" icon="plus-circle"
            >新增</a-button
          >
        </a-form-item>
      </a-form>
    </div>
    <div class="page-cu-main">
      <vuescroll>
        <a-table
          :columns="columns"
          :data-source="list"
          rowKey="id"
          bordered
          :pagination="false"
        >
          <template slot="state" slot-scope="text, record">
            <a-tag color="green" v-if="text == '1'">有效</a-tag>
            <a-tag color="orange" v-if="text == '2'">无效</a-tag>
          </template>
          <template slot="is_special" slot-scope="text, record">
            <!-- <a-tag color="green" v-if="text == '1'">是</a-tag>
             <a-tag color="orange" v-if="text == '2'">否</a-tag> -->
            <span v-if="text == '1'">是</span>
            <span v-if="text == '2'">否</span>
          </template>
          <!-- <span slot="action" slot-scope="text, record">
            <a @click="editAction(record)"><a-icon type="edit" /> 编辑</a>
            <a-divider type="vertical" />
            <a @click="delConfirm(record.id)" class="text-red"
              ><a-icon type="close" />删除</a
            >
          </span> -->
          <template slot="action" slot-scope="text, record">
            <!-- <a-button
              type="link"
              size="small"
              icon="edit"
              @click="() => editAction(record['id'])"
              >编辑</a-button
            > -->
            <!-- <a-button
              type="link"
              size="small"
              icon="file"
              @click="() => showUserLog(record['id'])"
              >学习记录</a-button
            > -->
            <a-button
              type="link"
              size="small"
              icon="team"
              @click="() => showUserList(record['id'])"
              >查看人员</a-button
            >
            <a-button
              type="link"
              size="small"
              icon="video-camera"
              @click="() => showVideoList(record['id'])"
              >查看视频</a-button
            >
            <a-button
              type="link"
              size="small"
              icon="minus-circle"
              v-if="record['state'] == '1'"
              @click="() => enabledState(record['id'], 2, '你确定要禁用吗？')"
              >禁用</a-button
            >
            <a-button
              type="link"
              size="small"
              icon="check-circle"
              v-if="record['state'] == '2'"
              @click="enabledState(record['id'], 1, '你确定要启用吗？')"
              >启用</a-button
            >
            <!-- <a-menu-item
                  v-if="record.company_id != null"
                  @click="() => delConfirm(record['id'])"
                >
                  <a-button type="link" size="small" icon="delete"
                    >删除</a-button
                  >
                </a-menu-item>
                <a-menu-divider /> -->
          </template>
        </a-table>
        <div class="page-cu-pagination">
          <a-pagination
          :show-total="(total, range) => `总数：${total} 条`"
            :page-size.sync="page.pageSize"
            :total="page.totalRow"
            v-model="page.start"
            @change="changePage"
          />
        </div>
      </vuescroll>
    </div>

    <a-modal
      title="新增"
      :visible="actionVisible"
      width="600px"
      @ok="actionData"
      ok-text="确认"
      cancel-text="取消"
      @cancel="cancel"
      :confirmLoading="$store.state.requestLoading"
    >
      <a-form-model
        ref="formModel"
        :rules="formModelRules"
        :model="formModel"
        layout="vertical"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 16 }"
      >
        <a-form-model-item label="学习方案名称" prop="name">
          <a-input
            v-model="formModel.name"
            placeholder="请输入学习方案名称"
          ></a-input>
        </a-form-model-item>
        <a-form-model-item label="起止日期" prop="last_time">
          <a-range-picker
            :placeholder="['开始日期', '截止日期']"
            :value="[formModel.start_time, formModel.last_time]"
            @change="onChangeDate"
          />
        </a-form-model-item>
        <a-form-model-item label="选择视频" prop="video_ids" v-if="isAdd">
          <a-tag
            color="cyan"
            closable
            v-for="(item, index) in formModel.videos"
            :key="index"
            @close="closeVideoLog(index)"
          >
            {{ item.title }}
          </a-tag>
          <a-button
            type="primary"
            size="small"
            @click="selectVideo(formModel.video_ids)"
          >
            查看选择视频<a-icon type="right" />
          </a-button>
        </a-form-model-item>

        <a-form-model-item label="选择用户" prop="user_ids" v-if="isAdd">
          <a-tag
            color="cyan"
            closable
            v-for="(item, index) in formModel.users"
            :key="index"
            @close="closeUserLog(index)"
          >
            {{ item.name }}
          </a-tag>
          <a-button
            type="primary"
            size="small"
            @click="selectUser(formModel.user_ids)"
          >
            查看选择用户<a-icon type="right" />
          </a-button>
        </a-form-model-item>
        <a-form-model-item label="序号" prop="sort">
          <a-input v-model="formModel.sort" placeholder="请输入排序"></a-input>
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <a-modal
      v-model="videoVisible"
      title="选择视频"
      ok-text="确认"
      width="860px"
      @ok="addvideoFrom"
    >
      <div style="">
        <div style="margin-bottom: 15px">
          <a-form layout="inline">
            <a-form-item>
              <a-input
                v-model="videoPage.key"
                placeholder="请输入关键字"
              ></a-input>
            </a-form-item>
            <a-form-item>
              <a-select
                style="width: 300px"
                v-model="videoPage.video_category_id"
                placeholder="请选择视频分类"
              >
                <a-select-option value="">全部</a-select-option>
                <a-select-option
                  v-for="(item, index) in videoTree"
                  :key="index"
                  :title="item.name"
                  :value="item.id"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
            <a-form-item>
              <a-button type="primary" @click="searchvList" icon="search"
                >搜索</a-button
              >
            </a-form-item>
          </a-form>
        </div>
        <a-table
          :row-selection="{
            selectedRowKeys: selectedVideoKeys,
            onChange: onSelectvChange,
          }"
          :columns="videoColumns"
          :data-source="videoList"
          @onChange="videoPageChange"
          :pagination="{
            total: videoPage.totalRow,
            defaultPageSize: videoPage.limit,
            defaultCurrent: 1,
          }"
          :scroll="{ x: 800, y: 500 }"
          bordered
          rowKey="id"
        >
          <template slot="video_url" slot-scope="text, record">
            <video
              :src="text"
              controls
              style="width: 90px; height: 60px; border: 1px dashed #d9d9d9"
            ></video>
          </template>
        </a-table>
      </div>
    </a-modal>

    <a-modal
      v-model="userVisible"
      title="选择用户"
      ok-text="确认"
      width="900px"
      @ok="adduserFrom"
    >
      <div style="">
        <div style="margin-bottom: 15px">
          <a-form layout="inline">
            <a-form-item>
              <a-input
                v-model="userPage.key"
                placeholder="请输入关键字"
              ></a-input>
            </a-form-item>
            <a-form-item>
              <a-select
                style="width: 200px"
                v-model="userPage.id"
                placeholder="请选择工种"
              >
                <a-select-option value="">全部</a-select-option>
                <a-select-option
                  v-for="(item, index) in jobJson"
                  :key="index"
                  :value="item.id"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
            <a-form-item>
              <a-select
                style="width: 200px"
                v-model="userPage.project_id"
                placeholder="请选择项目"
              >
                <a-select-option value="">全部</a-select-option>
                <a-select-option
                  v-for="(item, index) in proJson"
                  :key="index"
                  :title="item.name"
                  :value="item.id"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
            <a-form-item>
              <a-button type="primary" @click="searchuList" icon="search"
                >搜索</a-button
              >
            </a-form-item>
          </a-form>
        </div>
        <a-table
          :row-selection="{
            selectedRowKeys: selectedUserKeys,
            onChange: onSelectuChange,
          }"
          :columns="userColumns"
          :data-source="userList"
          @onChange="userPageChange"
          :pagination="{
            total: userPage.totalRow,
            defaultPageSize: userPage.limit,
            defaultCurrent: 1,
          }"
          :scroll="{ x: 800, y: 500 }"
          bordered
          rowKey="id"
        >
          <template slot="sex" slot-scope="text, record">
            <span v-if="text == '0'">未知</span>
            <span v-if="text == '1'">男</span>
            <span v-if="text == '2'">女</span>
          </template>
        </a-table>
      </div>
    </a-modal>

    <a-modal
      v-model="userLogVisible"
      title="学习记录"
      ok-text="确认"
      width="860px"
      @ok="adduserFrom"
    >
      <div style="max-height: 500px; overflow-y: auto; width: 100%">
        <div>
          <a-form layout="inline">
            <a-form-item>
              <a-input
                v-model="userLogPage.key"
                placeholder="请输入关键字"
              ></a-input>
            </a-form-item>
            <a-form-item>
              <a-select
                style="width: 120px"
                v-model="userLogPage.state"
                placeholder="请选择状态"
              >
                <a-select-option value="">全部</a-select-option>
                <a-select-option value="1">未观看</a-select-option>
                <a-select-option value="2">已观看</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item>
              <a-button type="primary" @click="searchlList" icon="search"
                >搜索</a-button
              >
            </a-form-item>
            <a-form-item>
              <a-button
                :icon="getExicon.icon"
                :disabled="getExicon.ed"
                @click="
                  $exportFun('/export/educationVideoLearnLog', userLogPage)
                "
                >导出</a-button
              >
            </a-form-item>
          </a-form>
        </div>
        <a-table
          :columns="LogColumns"
          :data-source="userLogList"
          :pagination="{
            total: userLogPage.totalRow,
            defaultPageSize: userLogPage.limit,
            defaultCurrent: 1,
          }"
          bordered
          rowKey="id"
        >
          <template slot="state" slot-scope="text, record">
            <a-tag color="orange" v-if="text == '1'">未观看</a-tag>
            <a-tag color="green" v-if="text == '2'">已观看</a-tag>
          </template>
        </a-table>
      </div>
    </a-modal>

    <a-modal
      v-model="userListVisible"
      title="查看人员"
      ok-text="确认"
      width="860px"
      @ok="userListVisible = false"
    >
      <div style="max-height: 500px; overflow-y: auto; width: 100%">
        <a-table :columns="UserColumns" :data-source="activeUserList" bordered>
          <template slot="sex" slot-scope="text, record">
            <span v-if="text == '0'">未知</span>
            <span v-if="text == '1'">男</span>
            <span v-if="text == '2'">女</span>
          </template>
          <template slot="state" slot-scope="text, record">
            <a-tag color="orange" v-if="text == '1'">未完成</a-tag>
            <a-tag color="green" v-if="text == '2'">已完成</a-tag>
          </template>
          <template slot="action" slot-scope="text, record">
            <a-button
              type="link"
              size="small"
              icon="file"
              @click="() => showUserLog(record['id'])"
              >学习记录</a-button
            >
          </template>
        </a-table>
      </div>
    </a-modal>

    <a-modal
      v-model="VideoListVisible"
      title="查看视频"
      ok-text="确认"
      width="860px"
      @ok="VideoListVisible = false"
    >
      <div style="max-height: 500px; overflow-y: auto; width: 100%">
        <a-table
          :columns="VideoColumns"
          :data-source="activeVideoList"
          bordered
        >
          <template slot="video_url" slot-scope="text, record">
            <video
              :src="text"
              controls
              style="width: 90px; height: 60px; border: 1px dashed #d9d9d9"
            ></video>
          </template>
        </a-table>
      </div>
    </a-modal>
  </div>
</template>

<script>
import * as Api from "./api";
export default {
  name: "index",
  data() {
    return {
      VideoListVisible: false,
      VideoColumns: [
        {
          title: "视频名称",
          dataIndex: "title",
        },
        {
          title: "创建时间",
          dataIndex: "create_time",
          key: "create_time",
          width: 120,
        },
        {
          title: "视频",
          dataIndex: "video_url",
          key: "video_url",
          scopedSlots: { customRender: "video_url" },
        },
        {
          title: "视频时长",
          dataIndex: "duration",
          key: "duration",
        },
      ],
      activeVideoList: [],
      userListVisible: false,
      activeUserList: [],
      isAdd: true, // TODO: 是否是新增操作
      planId: "",
      videoVisible: false,
      videoTree: [],
      selectedVideoKeys: [],
      selectedVideoList: [],
      videoPage: {
        start: 1,
        limit: 10,
        state: "1",
        video_category_id: undefined,
        totalRow: 0,
        key: "",
      },
      videoList: [],
      videoColumns: [
        {
          title: "视频名称",
          dataIndex: "title",
        },
        {
          title: "创建时间",
          dataIndex: "create_time",
          key: "create_time",
          width: 120,
        },
        {
          title: "视频",
          dataIndex: "video_url",
          key: "video_url",
          scopedSlots: { customRender: "video_url" },
        },
        {
          title: "视频时长",
          dataIndex: "duration",
          key: "duration",
        },
      ],
      userVisible: false,
      selectedUserKeys: [],
      user_list: [], // 用户数据
      jobJson: [],
      proJson: [],
      userList: [],
      selectedUserList: [],
      selectedUserKeys: [],
      userPage: {
        start: 1,
        limit: 10,
        totalRow: 0,
        key: "",
      },
      userColumns: [
        {
          title: "姓名",
          dataIndex: "name",
          width: 100,
        },
        {
          title: "性别",
          dataIndex: "sex",
          width: 80,
          align: "center",
        },
        {
          title: "所属项目",
          dataIndex: "pname",
          width: 200,
        },
        {
          title: "所属工种",
          dataIndex: "wname",
          width: 100,
        },
      ],
      UserColumns: [
        {
          title: "姓名",
          dataIndex: "name",
          width: 100,
        },
        {
          title: "性别",
          dataIndex: "sex",
          width: 80,
          align: "center",
        },
        {
          title: "所属项目",
          dataIndex: "pname",
          width: 200,
        },
        {
          title: "所属工种",
          dataIndex: "wname",
          width: 100,
        },
        {
          title: "状态",
          dataIndex: "video_state",
          width: 80,
          align: "center",
          scopedSlots: { customRender: "state" },
        },
        {
          title: "操作",
          width: 120,
          algin: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      userLogVisible: false,
      userLogList: [],
      LogColumns: [
        {
          title: "姓名",
          dataIndex: "uname",
          width: 100,
        },
        {
          title: "方案名称",
          dataIndex: "epname",
          width: 100,
        },
        {
          title: "视频名称",
          dataIndex: "vtitle",
          width: 100,
        },
        {
          title: "学习时间",
          dataIndex: "study_time",
          width: 100,
        },
        {
          title: "累计观看秒数",
          dataIndex: "total_learn_second",
          width: 100,
        },
        {
          title: "状态",
          dataIndex: "state",
          width: 80,
          align: "center",
          scopedSlots: { customRender: "state" },
        },
      ],
      userLogPage: {
        start: 1,
        limit: 10,
        totalRow: 0,
        state: undefined,
        user_id: "",
        education_plan_id: "",
        key: "",
      },
      formModel: {
        id: "",
        name: "",
        sort: "",
        start_time: null,
        last_time: null,
        video_ids: "",
        user_ids: "",
        videos: [],
        users: [],
      },
      video_list: {},
      formModelRules: {
        name: [
          { required: true, message: "请输入学习方案名称", trigger: "change" },
        ],
        last_time: [
          { required: true, message: "请选择起止日期", trigger: "change" },
        ],
      },

      columns: [
        {
          title: "方案名称",
          dataIndex: "name",
        },
        {
          title: "开始时间",
          dataIndex: "start_time",
        },
        {
          title: "截止时间",
          dataIndex: "last_time",
        },
        {
          title: "视频数量",
          dataIndex: "video_num",
        },
        {
          title: "人员数量",
          dataIndex: "user_num",
        },
        {
          title: "状态",
          dataIndex: "state",
          scopedSlots: { customRender: "state" },
        },
        {
          title: "操作",
          width: 300,
          algin: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      page: {
        start: 1,
        totalRow: 0,
        pageSize: 0,
        limit: 20,
        key: "",
        state: undefined,
      },
      list: [],
      actionVisible: false,
      record: "",
      menuList: [],
      showTree: false,
      expandedKeys: [],
      autoExpandParent: true,
      treeFields: {
        children: "children",
        title: "name",
        key: "id",
      },
      selectedIds: [],
      selectHalf: [],
      // 解决数据树父子节点选中的问题
      checkedKeys: { checked: [], halfChecked: [] },
    };
  },
  computed:{
    getExicon(){
      return this.$store.state.EXICON;
    }
  },
  mounted() {
    this.getList();
  },
  methods: {
    /**
     * 添加，更新
     *
     */
    actionData() {
      this.$refs.formModel.validate(async (valid) => {
        if (valid) {
          let res = null;
          console.log(this.formModel);
          if (this.formModel["id"]) {
            res = await Api.Update(this.formModel);
          } else {
            res = await Api.Save(this.formModel);
          }

          if (res && res["code"] == "0") {
            this.$message.success(res.message);
            this.actionVisible = false;
            this.getList();
          } else {
            this.$message.error(res.message);
          }
        }
      });
    },

    /*
     * 表单重置
     **/
    resetFormModel() {
      for (let key in this.formModel) {
        this.formModel[key] = "";
      }
    },
    // 菜单选择
    handlerCheck(n, e) {
      this.checkedKeys = { checked: n, halfChecked: e.halfCheckedKeys };
    },
    // 打开添加学习方案窗
    addAction() {
      this.isAdd = true;
      this.resetFormModel();
      this.actionVisible = true;
    },

    // 编辑
    async editAction(id) {
      this.planId = id;
      this.isAdd = false;
      this.resetFormModel();
      let res = await Api.Detail({ id });
      Object.keys(this.formModel).forEach((key) => {
        this.formModel[key] = res.target[key];
      });
      this.actionVisible = true;
    },
    // 查看人员
    async showUserList(id) {
      this.planId = id;
      this.isAdd = false;
      this.resetFormModel();
      let res = await Api.Detail({ id });
      this.activeUserList = res.target.users;
      this.userListVisible = true;
    },
    // 查看视频
    async showVideoList(id) {
      this.planId = id;
      this.isAdd = false;
      this.resetFormModel();
      let res = await Api.Detail({ id });
      this.activeVideoList = res.target.videos;
      this.VideoListVisible = true;
    },
    // 关闭窗口
    cancel() {
      this.actionVisible = false;
    },

    // 删除确认
    delConfirm(id) {
      let that = this;
      this.$confirm({
        title: "信息提示",
        okType: "danger",
        okText: "确定",
        cancelText: "取消",
        content: "您确定要删除吗?",
        onOk() {
          that.del(id);
        },
        onCancel() {},
      });
    },

    // 删除
    async del(id) {
      let res = await Api.Delete({ id: id });
      if (res && res.code == "0") {
        this.$message.success(res.message);
        this.getList();
      } else {
        this.$message.error(res.message);
      }
    },
    // 列表
    async getList() {
      let res = await Api.List({ ...this.page });
      this.page.totalRow = res.page.totalRow;
      this.page.pageSize = res.page.pageSize;
      this.list = res.page.list;
    },

    changePage(currentPage) {
      this.page.start = currentPage;
      this.getList();
    },
    // 搜索
    searchList() {
      this.page.start = 1;
      this.getList();
    },
    // 启用 / 禁用
    enabledState(id, state, msg) {
      let that = this;
      this.$confirm({
        title: "信息提示",
        okType: "success",
        okText: "确定",
        cancelText: "取消",
        content: msg,
        async onOk() {
          let params = {
            id: id,
            state: state,
          };
          let res = await Api.AdminEnabled(params);
          if (res && res["code"] == "0") {
            that.getList();
            that.$message.success(res.message);
          } else {
            that.$message.error(res.message);
          }
        },
        onCancel() {},
      });
    },
    specialChange(value) {
      console.log(value.target.value);
      if (value.target.value == 2) {
        this.formModel.special_content = "";
      }
    },
    // 起止时间
    onChangeDate(date, dateString) {
      this.formModel.start_time = dateString[0];
      this.formModel.last_time = dateString[1];
    },
    // 删除视频tag
    closeVideoLog(index) {
      let vsArr = this.formModel.video_ids.split(",");
      vsArr.splice(index, 1);
      this.formModel.video_ids = vsArr.join(",");
      // this.formModel.videos.splice(index,1);
      console.log(this.formModel.video_ids);
    },
    // 获取视频分页数据
    async getvideoData() {
      const res = await Api.videoList(this.videoPage);
      this.videoList = res.page.list;
      this.videoPage.totalRow = res.page.totalRow;
    },
    /**
     * 获取视频分类
     */
    async getVideoTreeList() {
      const res = await Api.getVideoTreeList();
      this.videoTree = res.treeList;
    },
    /**
     * 视频分页操作
     */
    videoPageChange(e) {
      console.log(e["current"]);
      this.videoPage.start = e["current"];
      this.getvideoData();
    },
    // 选择视频弹框
    selectVideo(ids) {
      this.videoVisible = true;
      this.getvideoData();
      this.getVideoTreeList();
      this.selectedVideoKeys = ids != "" ? ids.split(",").map(parseFloat) : [];
    },
    // 视频搜索
    searchvList() {
      this.videoPage.start = 1;
      this.getvideoData();
    },

    // 监听视频表格选中变化
    onSelectvChange(keys, arr) {
      console.log(arr);
      this.selectedVideoList = arr;
      this.selectedVideoKeys = keys;
    },
    // 确认选择视频
    addvideoFrom() {
      this.formModel.video_ids = this.selectedVideoKeys.join(",");
      this.formModel.videos = this.selectedVideoList;
      this.videoVisible = false;
      console.log(this.formModel.video_ids);
    },
    /**
     * *****************选择用户*************************
     */

    /**
     * 获取用户分页数据
     */
    async getuserData() {
      const res = await Api.getuserData(this.userPage);
      this.userList = res.page.list;
      this.userPage.totalRow = res.page.totalRow;
    },

    // 选择用户弹框
    selectUser(ids) {
      this.userVisible = true;
      this.getuserData();
      this.getWorkJson();
      this.getProJson();
      this.selectedUserKeys = ids != "" ? ids.split(",").map(parseFloat) : [];
    },
    // 监听视频表格选中变化
    onSelectuChange(keys, arr) {
      console.log(arr);
      this.selectedUserList = arr;
      this.selectedUserKeys = keys;
    },
    closeUserLog(index) {
      let vsArr = this.formModel.user_ids.split(",");
      this.formModel.user_ids = vsArr.splice(index, 1).join(",");
      // this.formModel.users.splice(index,1)
    },
    searchuList() {
      this.userPage.start = 1;
      this.getuserData();
    },
    // 获取工种下拉数据
    async getWorkJson() {
      let res = await Api.getWorkJson({ state: "1" });
      this.jobJson = res.list;
    },
    async getProJson() {
      let res = await Api.getProJson({ limit: 100000, state: "1" });
      this.proJson = res.page.list;
    },
    /**
     * 用户分页操作
     */
    userPageChange(e) {
      console.log(e["current"]);
      this.userPage.start = e["current"];
      this.getuserData();
    },
    // 确认选择用户
    adduserFrom() {
      this.formModel.user_ids = this.selectedUserKeys.join(",");
      this.formModel.users = this.selectedUserList;
      this.userVisible = false;
    },

    showUserLog(id) {
      this.userLogVisible = true;
      this.userLogPage.user_id = id;
      this.userLogPage.education_plan_id = this.planId;
      this.getUserLogList();
    },
    // 获取视频学习记录
    async getUserLogList() {
      const res = await Api.userLogList(this.userLogPage);
      this.userLogList = res.page.list;
      this.userLogPage.totalRow = res.page.totalRow;
    },
    searchlList() {
      this.userLogPage.start = 1;
      this.getUserLogList();
    },
  },
};
</script>

<style scoped>
</style>

